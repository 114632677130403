import {} from 'gatsby';

import {
	ButtonContainer,
	StyledError,
	StyledForm,
	StyledGap,
	StyledInfo,
	StyledInput,
	StyledLabel,
} from '../../styles/logowanie';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { StyledArticle, StyledButton } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import { useAuth } from '../../hooks/useAuth';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Logowanie dla pracowników'
		description='Formularz logowania dla pracowników Spółki.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const LogowaniePage = () => {
	const auth = isBrowser()
		? useAuth()
		: {
				user: '',
				role: '',
				verifyUser: () => {},
				signIn: () => {},
				signOut: () => {},
		  };

	const [login, setLogin] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		if (auth.user) {
			switch (auth.role) {
				case 'tender':
					navigate('/konto/przetargi/lista');
					break;
				case 'admin':
					navigate('/konto/przetargi/lista');
					break;
				default:
					navigate('/');
			}
		}
	}, [auth.user]);

	const handleFormSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (disabledButton) {
			return;
		}
		setDisabledButton(true);
		if (!isFormValid()) {
			return;
		}
		const loginSuccessfull = await auth.signIn(login, password);
		if (!loginSuccessfull) {
			setError('Nieprawidłowy numer pracownika lub hasło.');
		}
		setDisabledButton(false);
	};

	const isFormValid = () => parseInt(login) > 0 && password !== '';

	const numberFieldRef = useRef<HTMLInputElement>(null);
	const passwordFieldRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (numberFieldRef && numberFieldRef.current) {
			numberFieldRef.current.focus();
		}
	}, []);

	if (auth.user) {
		return null;
	}

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Logowanie
				</StyledH2>
				{error ? (
					<StyledError>Formularz zawiera błędy: {error}</StyledError>
				) : (
					<StyledGap />
				)}
				<StyledForm>
					<StyledLabel htmlFor="login">Numer pracownika:</StyledLabel>
					<StyledInput
						autoCapitalize="none"
						autoCorrect="off"
						id="login"
						type="number"
						min="1"
						max="9999"
						name="login"
						ref={numberFieldRef}
						spellCheck="false"
						value={login}
						onChange={(e) => {
							setLogin(e.target.value);
						}}
						onKeyDown={(e) => {
							if (
								e.key === 'Enter' &&
								login !== '' &&
								passwordFieldRef &&
								passwordFieldRef.current
							) {
								e.preventDefault();
								passwordFieldRef.current.focus();
							}
						}}
					/>
					<StyledLabel htmlFor="password">Hasło:</StyledLabel>
					<StyledInput
						autoComplete="current-password"
						id="password"
						type="password"
						name="password"
						ref={passwordFieldRef}
						maxLength={256}
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter' && login !== '' && password !== '') {
								e.preventDefault();
								handleFormSubmit(e);
							}
						}}
					/>
					<ButtonContainer>
						<StyledButton
							role="button"
							onClick={handleFormSubmit}
							disabled={disabledButton}
						>
							ZALOGUJ
						</StyledButton>
					</ButtonContainer>
				</StyledForm>
				<StyledInfo>
					Zapomniałeś/aś hasła ?&nbsp;
					<Link to="/konto/jak-odzyskac-haslo">
						Dowiedz się jak odzyskać hasło.
					</Link>
				</StyledInfo>
			</StyledArticle>
		</Layout>
	);
};

export default LogowaniePage;
