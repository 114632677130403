import styled from 'styled-components';

export const StyledForm = styled.form`
	margin: 0 auto;
	max-width: 360px;
	padding: 16px;
	width: 100%;
`;

export const StyledLabel = styled.label`
	display: block;
	font-size: 0.9em;
	margin-bottom: 5px;
`;

export const StyledInput = styled.input`
	background-color: ${(props) => props.theme.articleBackgroundColor};
	border-color: ${(props) => props.theme.borderColor};
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font-size: 1em;
	line-height: 1em;
	margin: 0;
	margin-bottom: 16px;
	-moz-outline-radius: 0;
	padding: 12px 16px;
	width: 100%;
	&:focus {
		box-shadow: 0 0 1px 4px ${(props) => props.theme.borderColorActive};
		outline: 0;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 16px;
`;

export const StyledError = styled.div`
	background-color: ${(props) => props.theme.errorBackgroundColor};
	color: ${(props) => props.theme.errorText};
	line-height: 1.5;
	margin: 16px;
	padding: 16px;
`;

export const StyledGap = styled.div`
	margin-top: 16px;
`;

export const StyledInfo = styled.div`
	background-color: ${(props) => props.theme.infoBackgroundColor};
	color: ${(props) => props.theme.infoText};
	line-height: 1.5;
	margin: 16px;
	padding: 16px;
	a {
		color: ${(props) => props.theme.infoLinkText};
	}
`;
